import React, { useState, useCallback } from "react";
import { MantineProvider, createTheme, Popover, Input, Group, Divider } from '@mantine/core';
import { DatesProvider, DatePicker, DatesRangeValue } from '@mantine/dates';
import { Temporal } from "@js-temporal/polyfill";

interface NamedOption {
  name: string;
  label: string;
  startDate: Temporal.PlainDate;
  endDate: Temporal.PlainDate;
}

const DateSelect = ({
  dateStart, dateEnd, optionName, namedOptions, onDatesChanged
}: {
    dateStart: Temporal.PlainDate;
    dateEnd: Temporal.PlainDate;
    optionName: string;
    namedOptions: NamedOption[];
    onDatesChanged: (start: Temporal.PlainDate, end: Temporal.PlainDate, optionName: string) => void;
  }) => {
  const leftIcon = (<span className="icon"><i className="fas fa-calendar"></i></span>);
  const rightIcon = (<span className="icon"><i className="fas fa-chevron-down"></i></span>);

  const timezone = 'Pacific/Auckland';
  const plainDateToDate = (plainDate: Temporal.PlainDate) => new Date(plainDate.toZonedDateTime(timezone).epochMilliseconds);
  const dateToPlainDate = (date: Date) => Temporal.Instant.from(date.toISOString()).toZonedDateTimeISO(timezone).toPlainDate();
  const localDateFormat = new Intl.DateTimeFormat("en-NZ", {
    timeZone: "Pacific/Auckland",
    dateStyle: "full",
  });

  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [dateRange, setDateRange] = useState<DatesRangeValue>([plainDateToDate(dateStart), plainDateToDate(dateEnd)]);
  const [selectedOptionName, setSelectedOptionName] = useState<string>(optionName);
  const [appliedDateRange, setAppliedDateRange] = useState<DatesRangeValue>(dateRange);

  const onChange = useCallback((range: DatesRangeValue, optionName: string) => {
    setDateRange(range);

    if (range[0] !== null && range[1] !== null) {
      setDropdownOpened(false);
      setAppliedDateRange(range);
      setSelectedOptionName(optionName);
      onDatesChanged(dateToPlainDate(range[0]), dateToPlainDate(range[1]), optionName);
    }
  }, [onDatesChanged]);

  const formatDatesRangeValue = (range: DatesRangeValue, optionName: string) => {
    const option = namedOptions.find((entry) => entry?.name == optionName);
    if (option) {
      return option.label;
    } else {
      return `${range[0] ? localDateFormat.format(range[0]) : ''} - ${range[1] ? localDateFormat.format(range[1]) : ''}`
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDropdownOpened(!dropdownOpened);
  };

  const handleDropdownOpenedChanged = (opened: boolean) => {
    setDropdownOpened(opened);
    if (!opened) setDateRange(appliedDateRange); // abandon half-entered changes, if any
  };

  const namedOptionTags = namedOptions.map((entry, index) => {
    if (!entry) {
      return <Divider key={`divider${index}`} />
    } else if (entry.name == selectedOptionName) {
      return <p key={entry.name} className="selected">{entry.label}</p>;
    } else {
      return <p key={entry.name}><a onClick={() => onChange([plainDateToDate(entry.startDate), plainDateToDate(entry.endDate)], entry.name)}>{entry.label}</a></p>;
    }
  });

  return (
    <MantineProvider theme={createTheme({ defaultRadius: "0.375rem" })}>
      <DatesProvider settings={{ timezone: timezone }}>
        <Popover
          opened={dropdownOpened}
          onChange={handleDropdownOpenedChanged}
          trapFocus={false}
          position="bottom-start"
          withRoles={false}
          offset={0}
        >
          <Popover.Target>
            <Input.Wrapper className="DateSelect-root">
              <Input
                data-dates-input
                onClick={handleButtonClick}
                leftSection={leftIcon}
                rightSection={rightIcon}
                leftSectionPointerEvents="none"
                rightSectionPointerEvents="none"
                component="button"
                pointer={true}
                size="md"
                aria-haspopup="dialog"
              >
              {formatDatesRangeValue(appliedDateRange, selectedOptionName)}
              </Input>
            </Input.Wrapper>
          </Popover.Target>
          <Popover.Dropdown
            onMouseDown={(event) => event.preventDefault()}
            data-dates-dropdown
          >
            <Group>
              <div className="DateSelect-named-options">
                {namedOptionTags}
              </div>
              <Divider orientation="vertical" />
              <DatePicker
                type="range"
                value={dateRange}
                defaultDate={appliedDateRange[0] ?? plainDateToDate(dateEnd)}
                onChange={(range) => onChange(range, "")}
                allowSingleDateInRange={true}
              />
            </Group>
          </Popover.Dropdown>
        </Popover>
      </DatesProvider>
    </MantineProvider>
  );
}

export { DateSelect };
