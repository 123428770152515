import React, { useState, useCallback } from "react";
import { MantineProvider, createTheme, useCombobox, Pill, Combobox, CheckIcon, Group, PillsInput, Input } from '@mantine/core';

type Store = { id: number; name: string };

const MAX_DISPLAYED_VALUES = 3;

const StoreFilter = ({ 
  initiallySelectedStores,
  stores,
  onStoresChanged
}: { 
  initiallySelectedStores: number[];
  stores: Store[];
  onStoresChanged: (newValues: number[]) => void;
  }) => {
  const [appliedValues, setAppliedValues] = useState<number[]>(initiallySelectedStores);
  const [values, setValues] = useState<number[]>(initiallySelectedStores);

  const applyValues = useCallback((newValues: number[]) => {
    if (newValues.length != appliedValues.length || newValues.some((el) => !appliedValues.includes(el))) {
      onStoresChanged(newValues);
      setAppliedValues(newValues);
    }
  }, [onStoresChanged, appliedValues]);
  const combobox = useCombobox({
    onDropdownClose: () => {
      applyValues(values);
      combobox.resetSelectedOption();
    },
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');
    },
  });

  const leftIcon = (<span className="icon"><i className="fas fa-store"></i></span>);
  const rightIcon = (<span className="icon"><i className="fas fa-chevron-down"></i></span>);

  const handleValueSelect = useCallback((val: number) => {
    setValues((current) => {
      const newValues = current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
      if (!combobox.dropdownOpened) applyValues(newValues);
      return newValues;
    });
  }, [combobox.dropdownOpened, applyValues]);

  const handleValueRemove = useCallback((val: number) => {
    setValues((current) => {
      const newValues = current.filter((v) => v !== val);
      if (!combobox.dropdownOpened) applyValues(newValues);
      return newValues;
    });
  }, [combobox.dropdownOpened, applyValues]);

  const displayedValues = values
    .slice(0, MAX_DISPLAYED_VALUES === values.length ? MAX_DISPLAYED_VALUES : MAX_DISPLAYED_VALUES - 1)
    .map((item) => (
      <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {stores.find(s => s.id == item)?.name}
      </Pill>
    ));

  const options = stores.map((item) => (
    <Combobox.Option value={item.id.toString()} key={item.id} active={values.includes(item.id)}>
      <Group gap="sm">
        {values.includes(item.id) ? <CheckIcon size={12} /> : null}
        <span>{item.name}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <MantineProvider theme={createTheme({ defaultRadius: "0.375rem" })}>
      <Combobox store={combobox} onOptionSubmit={v => handleValueSelect(Number.parseInt(v))} withinPortal={false} offset={0}>
        <Combobox.DropdownTarget>
          <PillsInput pointer onClick={() => combobox.toggleDropdown()} leftSection={leftIcon} rightSection={rightIcon} leftSectionPointerEvents="none" rightSectionPointerEvents="none" size="md">
            <Pill.Group>
              {values.length > 0 ? (
                <>
                  {displayedValues}
                  {values.length > MAX_DISPLAYED_VALUES && (
                    <Pill>+{values.length - (MAX_DISPLAYED_VALUES - 1)} more</Pill>
                  )}
                </>
              ) : (
                <Input.Placeholder>All stores</Input.Placeholder>
              )}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  type="hidden"
                  onBlur={() => combobox.closeDropdown()}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace') {
                      event.preventDefault();
                      handleValueRemove(values[values.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </MantineProvider>
  );
}

export { StoreFilter };
