import { Controller } from "@hotwired/stimulus";
import { createElement } from "react";
import { createRoot } from "react-dom/client";

import { DateSelect } from "src/date_select";
import { Temporal } from "@js-temporal/polyfill";

export default class DateSelectController extends Controller {
  static targets = ["select", "startInput", "endInput", "optionNameInput"];

  declare selectTarget: HTMLSpanElement;
  declare startInputTarget: HTMLInputElement;
  declare endInputTarget: HTMLInputElement;
  declare optionNameInputTarget: HTMLInputElement;
  declare hasOptionNameInputTarget: boolean;

  connect() {
    const dateStart = Temporal.PlainDate.from(this.startInputTarget.value);
    const dateEnd = Temporal.PlainDate.from(this.endInputTarget.value);
    const optionName = this.hasOptionNameInputTarget ? this.optionNameInputTarget.value : "";

    const namedOptions = JSON.parse(this.data.get("named-options") ?? "[]").map(
      (entry: string[]) => entry && {
        name: entry[0],
        label: entry[1],
        startDate: Temporal.PlainDate.from(entry[2]),
        endDate: Temporal.PlainDate.from(entry[3]),
      }
    );

    const root = createRoot(this.selectTarget);

    root.render(createElement(DateSelect, {
      dateStart: dateStart,
      dateEnd: dateEnd,
      optionName: optionName,
      namedOptions: namedOptions,
      onDatesChanged: this.onChange.bind(this),
    }));

    this.dispatch("init", { detail: { rangeStart: dateStart, rangeEnd: dateEnd, optionName: optionName } });
  }

  onChange(rangeStart: Temporal.PlainDate, rangeEnd: Temporal.PlainDate, optionName: string) {
    this.startInputTarget.value = rangeStart.toString();
    this.endInputTarget.value = rangeEnd.toString();
    if (this.hasOptionNameInputTarget) this.optionNameInputTarget.value = optionName;
    this.dispatch("change", { detail: { rangeStart: rangeStart, rangeEnd: rangeEnd, optionName: optionName } });
  }
}
