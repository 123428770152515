import { Controller } from "@hotwired/stimulus";
import { Temporal } from "@js-temporal/polyfill";
import { visit } from "@hotwired/turbo"

export default class FilterFormController extends Controller {
  storeIds : number[] = [];
  rangeStart : Temporal.PlainDate | null = null;
  rangeEnd : Temporal.PlainDate | null = null;
  optionName = "";

  static values = {
    targetTurboFrame: String
  }

  declare targetTurboFrameValue: string;

  initStoreIds({
    detail: { storeIds }
  }: {
    detail: { storeIds: number[] };
  }) {
    this.storeIds = storeIds;
  }

  initDates({
    detail: { rangeStart, rangeEnd, optionName }
  }: {
    detail: { rangeStart: Temporal.PlainDate; rangeEnd: Temporal.PlainDate; optionName: string };
  }) {
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
    this.optionName = optionName;
  }

  updateStores({ 
    detail: { storeIds }
  }: {
    detail: { storeIds: number[] };
  }) {
    this.storeIds = storeIds;
    this.reload();
  }

  updateDates({
    detail: { rangeStart, rangeEnd, optionName }
  }: { 
    detail: { rangeStart: Temporal.PlainDate; rangeEnd: Temporal.PlainDate; optionName: string };
  }) {
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
    this.optionName = optionName;
    this.reload();
  }

  // Reload the page, setting the selected query params
  reload() {
    const queryParams = new URLSearchParams(window.location.search);

    // So that the update_store_ids param is always present, even when given an empty array next line down
    queryParams.set("update_store_ids[]", "");
    this.storeIds.forEach((storeId) => queryParams.append("update_store_ids[]", storeId.toString()));
    queryParams.set("update_start_date", this.rangeStart ? this.rangeStart.toString() : "")
    queryParams.set("update_end_date", this.rangeEnd ? this.rangeEnd.toString() : "")
    queryParams.set("update_date_option_name", this.optionName);

    // If we're updating the filter we want to make sure we clear the pagination
    queryParams.delete("page")

    const path_and_query = window.location.pathname + "?" + queryParams.toString();

    if (this.targetTurboFrameValue != "") {
      visit(path_and_query, { frame: this.targetTurboFrameValue });
    } else {
      visit(path_and_query);
    }
  }
}
